import type {
  ArticleDTO,
  Person,
  Podcast,
  QADtoListResponseDTO,
  QADto,
} from '~/typesAuto/apicore/v1'
import type { CalenderDTO } from '~/typesAuto/apicore/v2'
import type {
  Article as SagaArticle,
  BaseContent as SagaBaseContent,
  ArticlePaper as SagaArticlePaper,
  Review as SagaReview,
} from '~/typesAuto/saga/v2'
import type {
  ContentArticle,
  RSSFull,
  SubjectInfo,
  WriterFull,
  Writer,
  FrontpageTheme,
  Article,
} from '~/typesManual/content_api/article'
import type { ContentCalendar } from '~/typesManual/content_api/calendar'
import type { Subject } from '~/typesManual/content_api/subject'
import type { ThemeBubble } from '~/typesManual/content_api/theme_bubble'
import type { WeeklyTheme } from '~/typesManual/content_api/weekly_theme'

export type Magazine = {
  RecordId: number
  Name: string
  ImageUrl: string
  Title: string
  ShortTitle: string
  Link: string
  IssueEmbedCode: string
  OnlineDate: string
  Description: string
}

type OldPerson = {
  BirthPlace: string
  Birthday: string
  DeathYear: number
  Education: string
  Facebook: string
  FormerTitles: string
  Image: string
  Instagram: string
  Linkedin: string
  MasterId?: number
  Name: string
  Photographer: string
  RecordId: number
  Title: string
  Twitter: string
  UrlKey: string
  Website: string
}

export type ContentArticleWithPerson = ContentArticle & { Persons: OldPerson[] }

export default () => {
  const { apiCoreFetch, apiFetch, sagaApiFetch } = useApiFetch()
  return {
    async articleFull(id: string, paper: number, accesstoken?: string) {
      return await apiCoreFetch<ArticleDTO>(`articles/${id}`, {
        query: { paper, accesstoken },
      })
    },
    async articles({
      paper,
      type,
      id,
      count,
      page,
      frontend,
      offset,
      primaryOnly,
      podcastTypes,
    }: {
      paper: number
      type?: string
      id?: string | number
      count?: number
      page?: number
      frontend?: boolean
      offset?: number
      primaryOnly?: boolean
      podcastTypes?: string
    }) {
      return apiFetch<(ContentArticle | SubjectInfo)[]>('Articles', {
        query: {
          paper,
          type,
          id,
          pageSize: count,
          page,
          frontend,
          offset,
          primaryOnly,
          podcastTypes,
        },
      })
    },
    async frontpageArticles({
      paper,
      count,
      offset,
    }: {
      paper: number
      offset?: number
      count?: number
    }): Promise<ContentArticle[]> {
      const articles = await sagaApiFetch<{
        data: (
          | (SagaArticle & { $type: 'Article' })
          | (SagaReview & { $type: 'Review' })
          | (SagaBaseContent & {
              $type: 'FrontpageTheme'
              title: string
              description: string
              articles: SagaArticle[]
              priority: number
            })
        )[]
      }>('/v2/articles', {
        query: {
          paperId: paper,
          $skip: offset,
          $top: count,
        },
      })

      const convertArticlePapers = (articlePapers: SagaArticlePaper[]) => {
        if (!articlePapers.length) return []
        return articlePapers.map((articlePaper) => {
          return {
            Article: articlePaper.article,
            Paper: articlePaper.paper?.id || undefined,
            CreateTime: articlePaper.paper?.createTime,
            Primary: articlePaper.primary,
            Name: articlePaper.paper?.name || undefined,
            PublishingDate: articlePaper.publishingDateTime,
            RecordId: articlePaper.id,
            UpdateDate: articlePaper.updateDateTime,
          }
        })
      }

      const convertPodcast = (article: SagaArticle): Podcast => {
        return {
          name: article.podcastInfo?.type.name || undefined,
          urlKey: article.slug || undefined,
          image: article.podcastInfo?.type.image || undefined,
          text: article.podcastInfo?.type.text || undefined,
          duration: article.podcastInfo?.file.duration.toString() || undefined,
        }
      }

      const convertArticle = (
        article: SagaArticle | SagaReview
      ): ContentArticle => {
        return {
          Type: article.typeId || undefined,
          RecordId: article.id,
          Headline: article.headline || undefined,
          MainTeaser: article.teaser || undefined,
          UrlKey: article.slug || undefined,
          ImageUrl: article.image || undefined,
          TypeName: article.typeName || undefined,
          Papers: convertArticlePapers(article.articlePapers || []) || [],
          Podcast: convertPodcast(article) || undefined,
          Writers:
            article.authors?.map(
              ({ person }): Writer => ({
                RecordId: person.id || 0,
                Name: person.name || '',
                Image: person.image || '',
                UrlKey: person.slug || '',
                Type: person.legacyType || '',
              })
            ) || [],
          paywall: article.paywall || false,
        }
      }

      const convertFrontpageTheme = (
        theme: SagaBaseContent & {
          $type: 'FrontpageTheme'
          title: string
          description: string
          articles: SagaArticle[]
          priority: number
        }
      ): FrontpageTheme => ({
        Title: theme.title,
        Description: theme.description,
        Articles: theme.articles.map(convertArticle),
        ThemeId: theme.id?.toString() || '',
        ThemeUrl: theme.slug || '',
        ThemePriority: theme.priority.toString() || '',
      })

      return articles.data.map((article): ContentArticle => {
        if (article.$type === 'FrontpageTheme') {
          return { FrontpageTheme: convertFrontpageTheme(article) }
        }

        const contentArticle = convertArticle(article)

        if (article.$type === 'Review') {
          contentArticle.Rating = article.rating
        }

        return contentArticle
      })
    },
    rss({
      paper,
      type,
      count,
      days,
    }: {
      paper: number
      type?: number
      count?: number
      days?: number
    }) {
      return apiFetch<ContentArticle[]>('RSS', {
        query: { paper, type, count, days },
      })
    },
    async rssFull(id: string) {
      return apiFetch<RSSFull>('RSSFull', {
        query: { id },
      })
    },
    birthdays(paper: number, count: number) {
      return apiFetch<unknown[]>('Birthdays', {
        query: { paper, count },
      })
    },
    async calendar({
      paperId,
      count,
      type,
    }: {
      paperId: number
      count: number
      type?: string
    }) {
      const response = await apiFetch<
        {
          RecordId: number
          Headline: string
          Time: string
          Url: string
          Place: string
          Address: string
          Zipcode: string
          City: string
          Image: null
        }[]
      >('Calendar', {
        query: { paper: paperId, count, type },
      })

      return response.map(
        ({ RecordId, Headline, Time, Url, ...location }): ContentCalendar => ({
          RecordId,
          Headline,
          Time: parseCalendarTimeString(Time),
          Url,
          Place: location.Place || undefined,
          Address: location.Address || undefined,
          Zipcode: location.Zipcode || undefined,
          City: location.City || undefined,
        })
      )
    },
    async calendarFull(id: string, paper: number) {
      return apiCoreFetch<CalenderDTO>(`/v2/calendars/${id}`, {
        query: { paper },
      })
    },
    decisionchain(paper: string, count: number) {
      return apiFetch<unknown[]>('Decisionchains', {
        query: { paper, count },
      })
    },
    magazine(count?: number) {
      return apiFetch<Magazine[]>('Magazine', {
        query: { count },
      })
    },
    async mostRead(paper: number, subject: string) {
      return apiFetch<Article[]>('MostRead', {
        query: { paper, subject },
      })
    },
    async names({
      paper,
      type,
      subtype,
      id,
      count,
      page,
      offset,
    }: {
      paper: number
      type: string
      subtype: number | undefined
      id: number
      count: number
      page: number
      offset?: number | undefined
    }) {
      return apiFetch<ContentArticleWithPerson[]>('Articles', {
        query: {
          paper,
          type,
          id,
          pageSize: count,
          page,
          subtype,
          offset,
        },
      })
    },
    async personFull(id: string | number) {
      return apiCoreFetch<Person>(`/v2/persons/${id}`)
    },
    podcasts() {
      return apiFetch<Podcast[]>('Podcasts')
    },
    podcast(id: string) {
      return apiFetch<Podcast[]>('Podcast', { query: { id } })
    },
    QAList(
      paperId: number,
      pageSize: number,
      pageNumber: number,
      typeId?: number
    ) {
      return apiCoreFetch<QADtoListResponseDTO>('qas', {
        query: { paperId, typeId, pageSize, pageNumber },
      })
    },
    async QAFull(id: string, paper: number) {
      return apiCoreFetch<QADto>(`qas/${id}`, { query: { paper } })
    },
    quiz() {
      return apiFetch<unknown>('Quiz')
    },
    search({ words, paper, page, pageSize, type, searchOneYear }: unknown) {
      return apiFetch<unknown>('Search', {
        query: { words, paper, page, pageSize, type, searchOneYear },
      })
    },
    series({ paper, type }: unknown) {
      return apiFetch<Subject[]>('Subjects', { query: { paper, type } })
    },
    subject(id: string) {
      return apiFetch<Subject>('Subject', { query: { id } })
    },
    async bubbles(paper: number) {
      return apiFetch<ThemeBubble[]>('ThemeBubbles', { query: { paper } })
    },
    async mmThemes(query: { paper?: number; count: number; offset: number }) {
      const themes = await apiFetch<WeeklyTheme[]>('ThemeWeek', { query })

      return themes.map((theme) => ({
        ...theme,
        Url: convertThemeUrlToUrlKey(theme),
      }))
    },
    async mmTheme(themeIdOrUrlKey: string) {
      const theme = await apiFetch<WeeklyTheme>('ThemeWeekFull', {
        query: { id: themeIdOrUrlKey },
      })

      // If the theme is not found, the API returns an empty object instead of an error
      if (Object.keys(theme).length === 0)
        throw new Error(
          `No theme found with an id or urlKey of ${themeIdOrUrlKey}`
        )

      return {
        ...theme,
        Url: convertThemeUrlToUrlKey(theme),
      }
    },
    writerFull(id: string) {
      return apiFetch<WriterFull>('WriterFull', { query: { id } })
    },
  }
}

/**
 * Input format is 'DD-MM-YYYY HH:mm:ss'
 */
function parseCalendarTimeString(time: string) {
  const year = parseInt(time.substring(6, 10))
  const monthIndex = parseInt(time.substring(3, 5)) - 1
  const day = parseInt(time.substring(0, 2))
  const hour = parseInt(time.substring(11, 13))
  const minute = parseInt(time.substring(14, 16))
  const second = parseInt(time.substring(17, 19))

  return new Date(Date.UTC(year, monthIndex, day, hour, minute, second))
}

/**
 * Converts a theme's Url property to use the UrlKey instead of the RecordId
 */
function convertThemeUrlToUrlKey(theme: WeeklyTheme): string {
  return theme.Url.replace(theme.RecordId.toString(), theme.UrlKey)
}
