import { default as testnxGb4eh78qMeta } from "/opt/buildhome/repo/pages/test.vue?macro=true";
import { default as legacyMmDEtBc1xSMeta } from "/opt/buildhome/repo/pages/legacy.vue?macro=true";
import { default as clienttestzDPik2cn8JMeta } from "/opt/buildhome/repo/pages/clienttest.vue?macro=true";
import { default as pricesZgUgUL3BAzMeta } from "/opt/buildhome/repo/pages/prices.vue?macro=true";
import { default as receiptoQV4NCATD5Meta } from "/opt/buildhome/repo/pages/receipt.vue?macro=true";
import { default as wayfrVQRdCshUUMeta } from "/opt/buildhome/repo/pages/wayf.vue?macro=true";
import { default as cookieinfo4GvOZOzBw9Meta } from "/opt/buildhome/repo/pages/cookieinfo.vue?macro=true";
import { default as login4dfr6kRqBoMeta } from "/opt/buildhome/repo/pages/login.vue?macro=true";
import { default as ssoLogin7BvNhLICwSMeta } from "/opt/buildhome/repo/pages/ssoLogin.vue?macro=true";
import { default as sponsoredArticlesZfraShFPfvMeta } from "/opt/buildhome/repo/pages/sponsoredArticles.vue?macro=true";
import { default as sponsoredPoLj2oKcVRMeta } from "/opt/buildhome/repo/pages/sponsored.vue?macro=true";
import { default as indexy4DdxYIhQyMeta } from "/opt/buildhome/repo/pages/account/index.vue?macro=true";
import { default as profilerpPZ78QyoXMeta } from "/opt/buildhome/repo/pages/account/profile.vue?macro=true";
import { default as loginsecurityEakWPXnG3QMeta } from "/opt/buildhome/repo/pages/account/loginsecurity.vue?macro=true";
import { default as savedArticles5hTOP89LjbMeta } from "/opt/buildhome/repo/pages/account/savedArticles.vue?macro=true";
import { default as decisionchainsiLWeDJNn2PMeta } from "/opt/buildhome/repo/pages/account/decisionchains.vue?macro=true";
import { default as newsletterrIWb0i7VynMeta } from "/opt/buildhome/repo/pages/account/newsletter.vue?macro=true";
import { default as subscriptionUkjduZnamXMeta } from "/opt/buildhome/repo/pages/account/subscription.vue?macro=true";
import { default as userCreateOwFxp3dE9mMeta } from "/opt/buildhome/repo/pages/userCreate.vue?macro=true";
import { default as userForgotPasswordhobFhtIjWLMeta } from "/opt/buildhome/repo/pages/userForgotPassword.vue?macro=true";
import { default as userSetPasswordvlB5Bp0r4kMeta } from "/opt/buildhome/repo/pages/userSetPassword.vue?macro=true";
import { default as copyrightYQ2eg6AhYiMeta } from "/opt/buildhome/repo/pages/copyright.vue?macro=true";
import { default as typographyJkaCdJAZzBMeta } from "/opt/buildhome/repo/pages/typography.vue?macro=true";
import { default as searchW2JMgpGXtYMeta } from "/opt/buildhome/repo/pages/search.vue?macro=true";
import { default as newsletterdypMw9Ct6LMeta } from "/opt/buildhome/repo/pages/newsletter.vue?macro=true";
import { default as jobs1jPXQSk35lMeta } from "/opt/buildhome/repo/pages/jobs.vue?macro=true";
import { default as jobAddtiUyNAGqlMeta } from "/opt/buildhome/repo/pages/jobAd.vue?macro=true";
import { default as jobchangezAt9OBhe16Meta } from "/opt/buildhome/repo/pages/jobchange.vue?macro=true";
import { default as articleFxocv4itaaMeta } from "/opt/buildhome/repo/pages/article.vue?macro=true";
import { default as names0d0Q3d9O1iMeta } from "/opt/buildhome/repo/pages/names.vue?macro=true";
import { default as birthdayszYsQzyh6SpMeta } from "/opt/buildhome/repo/pages/birthdays.vue?macro=true";
import { default as papersp56gG0EDpPMeta } from "/opt/buildhome/repo/pages/papers.vue?macro=true";
import { default as podcaststa9VURdTdsMeta } from "/opt/buildhome/repo/pages/podcasts.vue?macro=true";
import { default as podcastB55YovcdhNMeta } from "/opt/buildhome/repo/pages/podcast.vue?macro=true";
import { default as raadhussvarliC6TidixUMeta } from "/opt/buildhome/repo/pages/raadhussvar.vue?macro=true";
import { default as magazineARYqoZf72sMeta } from "/opt/buildhome/repo/pages/magazine.vue?macro=true";
import { default as subscriptiontUoHEGZXa2Meta } from "/opt/buildhome/repo/pages/subscription.vue?macro=true";
import { default as result3wrA9VUwaMMeta } from "/opt/buildhome/repo/pages/subscription/checkout/result.vue?macro=true";
import { default as checkoutfTmbLfrHkNMeta } from "/opt/buildhome/repo/pages/subscription/checkout.vue?macro=true";
import { default as subjectPuY7vSRJyqMeta } from "/opt/buildhome/repo/pages/subject.vue?macro=true";
import { default as personuCNmZMkXgRMeta } from "/opt/buildhome/repo/pages/person.vue?macro=true";
import { default as writerUu6osQqVPuMeta } from "/opt/buildhome/repo/pages/writer.vue?macro=true";
import { default as news7QrcyjWbxlMeta } from "/opt/buildhome/repo/pages/news.vue?macro=true";
import { default as debateAElMSO0sOwMeta } from "/opt/buildhome/repo/pages/debate.vue?macro=true";
import { default as jobnamesGf7CeW5CYbMeta } from "/opt/buildhome/repo/pages/jobnames.vue?macro=true";
import { default as calendarlist0Ry8YTOD2CMeta } from "/opt/buildhome/repo/pages/calendarlist.vue?macro=true";
import { default as calendaryPechgWogzMeta } from "/opt/buildhome/repo/pages/calendar.vue?macro=true";
import { default as qaListiztY0BgQuEMeta } from "/opt/buildhome/repo/pages/qaList.vue?macro=true";
import { default as qavoqBCPG8TgMeta } from "/opt/buildhome/repo/pages/qa.vue?macro=true";
import { default as rssHyND0BWY79Meta } from "/opt/buildhome/repo/pages/rss.vue?macro=true";
import { default as electionsI8MuY52zE4Meta } from "/opt/buildhome/repo/pages/ct/elections.vue?macro=true";
import { default as electiondbGoRxOuywMeta } from "/opt/buildhome/repo/pages/ct/election.vue?macro=true";
import { default as electionCandidateTestJ4H0x1ETz3Meta } from "/opt/buildhome/repo/pages/ct/electionCandidateTest.vue?macro=true";
import { default as electionCandidateTestResultFPQv8k5puDMeta } from "/opt/buildhome/repo/pages/ct/electionCandidateTestResult.vue?macro=true";
import { default as electionProfilePagetzYloY8CMfMeta } from "/opt/buildhome/repo/pages/ct/electionProfilePage.vue?macro=true";
import { default as electionRobotArticle2JsBTUN2sYMeta } from "/opt/buildhome/repo/pages/ct/electionRobotArticle.vue?macro=true";
import { default as electionBallotdNF71QZYrvMeta } from "/opt/buildhome/repo/pages/ct/electionBallot.vue?macro=true";
import { default as termsjw1825O2ZdMeta } from "/opt/buildhome/repo/pages/terms.vue?macro=true";
import { default as termBISVRXU5NKMeta } from "/opt/buildhome/repo/pages/term.vue?macro=true";
import { default as paperAiSYW6Ic3lMeta } from "/opt/buildhome/repo/pages/paper.vue?macro=true";
import { default as b2c_45frontpageEpd2GExMJsMeta } from "/opt/buildhome/repo/pages/b2c-frontpage.vue?macro=true";
import { default as indexL7AVho63M1Meta } from "/opt/buildhome/repo/pages/folkemodet/index.vue?macro=true";
import { default as eventsApOCsWZV3RMeta } from "/opt/buildhome/repo/pages/folkemodet/events.vue?macro=true";
import { default as adsQwlR8fQkfDMeta } from "/opt/buildhome/repo/pages/ads.vue?macro=true";
import { default as coursesxyobE01v38Meta } from "/opt/buildhome/repo/pages/courses.vue?macro=true";
import { default as courseKhR2WkdYWDMeta } from "/opt/buildhome/repo/pages/course.vue?macro=true";
import { default as networksAP7DfIkEVrMeta } from "/opt/buildhome/repo/pages/networks.vue?macro=true";
import { default as networkEvNW0OTPDAMeta } from "/opt/buildhome/repo/pages/network.vue?macro=true";
import { default as conferencesWJKRLxUEGTMeta } from "/opt/buildhome/repo/pages/conferences.vue?macro=true";
import { default as conferencetNH3GbDN4uMeta } from "/opt/buildhome/repo/pages/conference.vue?macro=true";
import { default as facilitiesyMlObrtVKhMeta } from "/opt/buildhome/repo/pages/facilities.vue?macro=true";
import { default as yearbook0DFMuQCEESMeta } from "/opt/buildhome/repo/pages/yearbook.vue?macro=true";
import { default as commentsqAT6zNZONNMeta } from "/opt/buildhome/repo/pages/comments.vue?macro=true";
import { default as politicalSpeeches2y3CVb46nLMeta } from "/opt/buildhome/repo/pages/politicalSpeeches.vue?macro=true";
import { default as products2T6Jgugpz6Meta } from "/opt/buildhome/repo/pages/products.vue?macro=true";
import { default as component_45stubGZr1UkTkg3Meta } from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubGZr1UkTkg3 } from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "test",
    path: "/test",
    meta: {"layout":"stripped"},
    component: () => import("/opt/buildhome/repo/pages/test.vue")
  },
  {
    name: "quiz",
    path: "/quiz",
    meta: {"url":"/quiz.aspx"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue")
  },
  {
    name: "clienttest",
    path: "/ct",
    component: () => import("/opt/buildhome/repo/pages/clienttest.vue")
  },
  {
    name: "priceList",
    path: "/prisliste",
    component: () => import("/opt/buildhome/repo/pages/prices.vue")
  },
  {
    name: "pricing",
    path: "/priser",
    component: () => import("/opt/buildhome/repo/pages/prices.vue")
  },
  {
    name: "receipt",
    path: "/kvittering",
    meta: {"layout":"stripped"},
    component: () => import("/opt/buildhome/repo/pages/receipt.vue")
  },
  {
    name: "quizId",
    path: "/quiz/:id",
    meta: {"url":"/quiz.aspx"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue")
  },
  {
    name: "regering",
    path: "/regering",
    meta: {"url":"/regering.aspx"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue")
  },
  {
    name: "wayflogin",
    path: "/wayflogin",
    component: () => import("/opt/buildhome/repo/pages/wayf.vue")
  },
  {
    name: "cookieinfo",
    path: "/cookieinfo",
    component: () => import("/opt/buildhome/repo/pages/cookieinfo.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/opt/buildhome/repo/pages/login.vue")
  },
  {
    name: "ssoLogin",
    path: "/sso-login",
    component: () => import("/opt/buildhome/repo/pages/ssoLogin.vue")
  },
  {
    name: "birthdayGreeting",
    path: "/foedselsdagehilsen/:paramid",
    meta: {"url":"/BirthdayGreeting.aspx"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue")
  },
  {
    name: "accessmail",
    path: "/accessmail",
    meta: {"url":"/accessmail.aspx"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue")
  },
  {
    name: "afmeld",
    path: "/afmeld",
    meta: {"url":"/afmeld.aspx"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue")
  },
  {
    name: "afmeld_paper",
    path: "/:paper/afmeld",
    meta: {"url":"/afmeld.aspx"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue")
  },
  {
    name: "sponsored",
    path: "/sponsoreret",
    meta: {"url":"/sponsoreret"},
    component: () => import("/opt/buildhome/repo/pages/sponsoredArticles.vue")
  },
  {
    name: "sponsored_paper",
    path: "/:paper/sponsoreret",
    meta: {"url":"/sponsoreret"},
    component: () => import("/opt/buildhome/repo/pages/sponsoredArticles.vue")
  },
  {
    name: "sponsored_id",
    path: "/sponsoreret/:id",
    meta: {"url":"/sponsoreret"},
    component: () => import("/opt/buildhome/repo/pages/sponsored.vue")
  },
  {
    name: "sponsored_paper_id",
    path: "/:paper/sponsoreret/:id",
    meta: {"url":"/sponsoreret"},
    component: () => import("/opt/buildhome/repo/pages/sponsored.vue")
  },
  {
    name: "magasin_vis",
    path: "/magasin/vis/:id",
    meta: {"url":"/magasin/vis"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue")
  },
  {
    name: "kandidater_ft19",
    path: "/kandidater/ft19",
    meta: {"url":"/kandidater/ft19"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue")
  },
  {
    name: "kandidater_ep19",
    path: "/kandidater/ep19",
    meta: {"url":"/kandidater/ep19"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue")
  },
  {
    name: "account",
    path: "/konto",
    component: () => import("/opt/buildhome/repo/pages/account/index.vue")
  },
  {
    name: "account_profile",
    path: "/konto/profil",
    component: () => import("/opt/buildhome/repo/pages/account/profile.vue")
  },
  {
    name: "account_loginsecurity",
    path: "/konto/login-sikkerhed",
    component: () => import("/opt/buildhome/repo/pages/account/loginsecurity.vue")
  },
  {
    name: "saved_articles",
    path: "/konto/gemte-artikler",
    component: () => import("/opt/buildhome/repo/pages/account/savedArticles.vue")
  },
  {
    name: "account_decisionchains",
    path: "/konto/beslutningskaeder",
    component: () => import("/opt/buildhome/repo/pages/account/decisionchains.vue")
  },
  {
    name: "account_newsletter",
    path: "/konto/nyhedsbrev",
    component: () => import("/opt/buildhome/repo/pages/account/newsletter.vue")
  },
  {
    name: "account_subscription",
    path: "/konto/abonnement",
    component: () => import("/opt/buildhome/repo/pages/account/subscription.vue")
  },
  {
    name: "admin",
    path: "/admin",
    meta: {"url":"/admin"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue")
  },
  {
    name: "nejtak",
    path: "/nejtak",
    meta: {"url":"/nejtak.aspx"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue")
  },
  {
    name: "usercreate",
    path: "/usercreate",
    component: () => import("/opt/buildhome/repo/pages/userCreate.vue")
  },
  {
    name: "userconfirmation",
    path: "/userconfirmation",
    meta: {"url":"/userconfirmation"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue")
  },
  {
    name: "useravatar",
    path: "/useravatar",
    meta: {"url":"/useravatar"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue")
  },
  {
    name: "userforgotpassword",
    path: "/userforgotpassword",
    meta: {"url":"/userforgotpassword"},
    component: () => import("/opt/buildhome/repo/pages/userForgotPassword.vue")
  },
  {
    name: "usersetpassword",
    path: "/usersetpassword",
    component: () => import("/opt/buildhome/repo/pages/userSetPassword.vue")
  },
  {
    name: "copyright",
    path: "/copyright",
    component: () => import("/opt/buildhome/repo/pages/copyright.vue")
  },
  {
    name: "licens",
    path: "/licens",
    meta: {"url":"/licens"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue")
  },
  {
    name: "legacy-test",
    path: "/legacy-test",
    meta: {"url":"/xx.aspx"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue")
  },
  {
    name: "typography",
    path: "/typography",
    component: () => import("/opt/buildhome/repo/pages/typography.vue")
  },
  {
    name: "search",
    path: "/soeg",
    component: () => import("/opt/buildhome/repo/pages/search.vue")
  },
  {
    name: "paper_search",
    path: "/:paper/soeg",
    component: () => import("/opt/buildhome/repo/pages/search.vue")
  },
  {
    name: "searchwords",
    path: "/soeg/:words",
    component: () => import("/opt/buildhome/repo/pages/search.vue")
  },
  {
    name: "paper_searchwords",
    path: "/:paper/soeg/:words",
    component: () => import("/opt/buildhome/repo/pages/search.vue")
  },
  {
    name: "newsletter",
    path: "/nyhedsbrev",
    component: () => import("/opt/buildhome/repo/pages/newsletter.vue")
  },
  {
    name: "jobs",
    path: "/job",
    component: () => import("/opt/buildhome/repo/pages/jobs.vue")
  },
  {
    name: "job",
    path: "/job/:id",
    component: () => import("/opt/buildhome/repo/pages/jobAd.vue")
  },
  {
    name: "jobchange-index",
    path: "/jobskifte",
    component: () => import("/opt/buildhome/repo/pages/jobchange.vue")
  },
  {
    name: "jobchange",
    path: "/jobskifte/:id",
    component: () => import("/opt/buildhome/repo/pages/article.vue")
  },
  {
    name: "paper_jobchange-index",
    path: "/:paper/jobskifte",
    component: () => import("/opt/buildhome/repo/pages/jobchange.vue")
  },
  {
    name: "paper_jobchange",
    path: "/:paper/jobskifte/:id",
    component: () => import("/opt/buildhome/repo/pages/article.vue")
  },
  {
    name: "article",
    path: "/artikel/:id",
    component: () => import("/opt/buildhome/repo/pages/article.vue")
  },
  {
    name: "paper_article",
    path: "/:paper/artikel/:id",
    component: () => import("/opt/buildhome/repo/pages/article.vue")
  },
  {
    name: "names",
    path: "/navnenyt/:id",
    component: () => import("/opt/buildhome/repo/pages/article.vue")
  },
  {
    name: "paper_names",
    path: "/:paper/navnenyt/:id",
    component: () => import("/opt/buildhome/repo/pages/article.vue")
  },
  {
    name: "names-index",
    path: "/navnenyt",
    component: () => import("/opt/buildhome/repo/pages/names.vue")
  },
  {
    name: "paper_names-index",
    path: "/:paper/navnenyt",
    component: () => import("/opt/buildhome/repo/pages/names.vue")
  },
  {
    name: "birthdays",
    path: "/foedselsdage",
    component: () => import("/opt/buildhome/repo/pages/birthdays.vue")
  },
  {
    name: "paper_birthdays-index",
    path: "/:paper/foedselsdage",
    component: () => import("/opt/buildhome/repo/pages/birthdays.vue")
  },
  {
    name: "papers",
    path: "/nichemedier",
    component: () => import("/opt/buildhome/repo/pages/papers.vue")
  },
  {
    name: "podcast",
    path: "/podcast",
    component: () => import("/opt/buildhome/repo/pages/podcasts.vue")
  },
  {
    name: "podcastId",
    path: "/podcast/:podcast",
    component: () => import("/opt/buildhome/repo/pages/podcast.vue")
  },
  {
    name: "raadhussvar-index",
    path: "/raadhussvar",
    component: () => import("/opt/buildhome/repo/pages/raadhussvar.vue")
  },
  {
    name: "magazine",
    path: "/magasin",
    component: () => import("/opt/buildhome/repo/pages/magazine.vue")
  },
  {
    name: "subscription",
    path: "/abonnement",
    component: () => import("/opt/buildhome/repo/pages/subscription.vue")
  },
  {
    name: "paper_subscription",
    path: "/:paper/abonnement",
    component: () => import("/opt/buildhome/repo/pages/subscription.vue")
  },
  {
    name: "subscriptionCheckoutResult",
    path: "/abonnement/betaling/resultat",
    meta: {"layout":"stripped"},
    component: () => import("/opt/buildhome/repo/pages/subscription/checkout/result.vue")
  },
  {
    name: "subscriptionCheckout",
    path: "/abonnement/betaling",
    meta: {"layout":"stripped"},
    component: () => import("/opt/buildhome/repo/pages/subscription/checkout.vue")
  },
  {
    name: "subject",
    path: "/emne/:subject",
    component: () => import("/opt/buildhome/repo/pages/subject.vue")
  },
  {
    name: "paper_subject",
    path: "/:paper/emne/:subject",
    component: () => import("/opt/buildhome/repo/pages/subject.vue")
  },
  {
    name: "person",
    path: "/person/:person",
    component: () => import("/opt/buildhome/repo/pages/person.vue")
  },
  {
    name: "paper_person",
    path: "/:paper/person/:person",
    component: () => import("/opt/buildhome/repo/pages/person.vue")
  },
  {
    name: "writer",
    path: "/forfatter/:writer",
    component: () => import("/opt/buildhome/repo/pages/writer.vue")
  },
  {
    name: "paper_writer",
    path: "/:paper/forfatter/:writer",
    component: () => import("/opt/buildhome/repo/pages/writer.vue")
  },
  {
    name: "news",
    path: "/nyheder",
    component: () => import("/opt/buildhome/repo/pages/news.vue")
  },
  {
    name: "debate",
    path: "/debat",
    component: () => import("/opt/buildhome/repo/pages/debate.vue")
  },
  {
    name: "decisionchain",
    path: "/decisionchain",
    meta: {"url":"/decisionchain"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue")
  },
  {
    name: "decisionchain_id",
    path: "/decisionchain/:id",
    meta: {"url":"/decisionchain"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue")
  },
  {
    name: "paper_decisionchain",
    path: "/:paper/decisionchain",
    meta: {"url":"/decisionchain"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue")
  },
  {
    name: "paper_decisionchain_id",
    path: "/:paper/decisionchain/:id",
    meta: {"url":"/decisionchain"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue")
  },
  {
    name: "job-names",
    path: "/job-navne",
    component: () => import("/opt/buildhome/repo/pages/jobnames.vue")
  },
  {
    name: "paper_news",
    path: "/:paper/nyheder",
    component: () => import("/opt/buildhome/repo/pages/news.vue")
  },
  {
    name: "paper_debate",
    path: "/:paper/debat",
    component: () => import("/opt/buildhome/repo/pages/debate.vue")
  },
  {
    name: "paper_job-names",
    path: "/:paper/job-navne",
    component: () => import("/opt/buildhome/repo/pages/jobnames.vue")
  },
  {
    name: "cal",
    path: "/kalender",
    component: () => import("/opt/buildhome/repo/pages/calendarlist.vue")
  },
  {
    name: "calendar",
    path: "/kalender/:id",
    component: () => import("/opt/buildhome/repo/pages/calendar.vue")
  },
  {
    name: "paper_cal",
    path: "/:paper/kalender",
    component: () => import("/opt/buildhome/repo/pages/calendarlist.vue")
  },
  {
    name: "paper_calendar",
    path: "/:paper/kalender/:id",
    component: () => import("/opt/buildhome/repo/pages/calendar.vue")
  },
  {
    name: "qa-index",
    path: "/ministersvar",
    component: () => import("/opt/buildhome/repo/pages/qaList.vue")
  },
  {
    name: "qa",
    path: "/ministersvar/:id",
    component: () => import("/opt/buildhome/repo/pages/qa.vue")
  },
  {
    name: "paper_qa-index",
    path: "/:paper/ministersvar",
    component: () => import("/opt/buildhome/repo/pages/qaList.vue")
  },
  {
    name: "paper_qa",
    path: "/:paper/ministersvar/:id",
    component: () => import("/opt/buildhome/repo/pages/qa.vue")
  },
  {
    name: "paper_raadhussvar-index",
    path: "/:paper/raadhussvar",
    component: () => import("/opt/buildhome/repo/pages/raadhussvar.vue")
  },
  {
    name: "paper_raadhussvar",
    path: "/:paper/raadhussvar/:id",
    component: () => import("/opt/buildhome/repo/pages/qa.vue")
  },
  {
    name: "rss",
    path: "/rss/:id",
    component: () => import("/opt/buildhome/repo/pages/rss.vue")
  },
  {
    name: "paper_rss",
    path: "/:paper/rss/:id",
    component: () => import("/opt/buildhome/repo/pages/rss.vue")
  },
  {
    name: "elections",
    path: "/kandidattest",
    component: () => import("/opt/buildhome/repo/pages/ct/elections.vue")
  },
  {
    name: "election",
    path: "/kandidattest/:slug",
    component: () => import("/opt/buildhome/repo/pages/ct/election.vue")
  },
  {
    name: "electionTest",
    path: "/kandidattest/:slug/kandidattest",
    component: () => import("/opt/buildhome/repo/pages/ct/electionCandidateTest.vue")
  },
  {
    name: "electionTestResult",
    path: "/kandidattest/:slug/resultat",
    component: () => import("/opt/buildhome/repo/pages/ct/electionCandidateTestResult.vue")
  },
  {
    name: "electionProfile",
    path: "/kandidattest/:slug/profil/:person",
    component: () => import("/opt/buildhome/repo/pages/ct/electionProfilePage.vue")
  },
  {
    name: "electionArticle",
    path: "/kandidattest/:slug/valgresultat/:person",
    component: () => import("/opt/buildhome/repo/pages/ct/electionRobotArticle.vue")
  },
  {
    name: "electionBallot",
    path: "/kandidattest/:slug/valgkort",
    component: () => import("/opt/buildhome/repo/pages/ct/electionBallot.vue")
  },
  {
    name: "terms",
    path: "/handelsbetingelser",
    component: () => import("/opt/buildhome/repo/pages/terms.vue")
  },
  {
    name: "term",
    path: "/handelsbetingelser/:termId",
    component: () => import("/opt/buildhome/repo/pages/term.vue")
  },
  {
    name: "paper",
    path: "/:paper",
    component: () => import("/opt/buildhome/repo/pages/paper.vue")
  },
  {
    name: "frontpage",
    path: "/",
    component: () => import("/opt/buildhome/repo/pages/b2c-frontpage.vue")
  },
  {
    name: "folkemodet",
    path: "/folkemodet",
    component: () => import("/opt/buildhome/repo/pages/folkemodet/index.vue")
  },
  {
    name: "folkemodet_events",
    path: "/folkemodet/program",
    component: () => import("/opt/buildhome/repo/pages/folkemodet/events.vue")
  },
  {
    name: "ads",
    path: "/annoncer",
    component: () => import("/opt/buildhome/repo/pages/ads.vue")
  },
  {
    name: "courses",
    path: "/kurser",
    component: () => import("/opt/buildhome/repo/pages/courses.vue")
  },
  {
    name: "course",
    path: "/kurser/:id",
    component: () => import("/opt/buildhome/repo/pages/course.vue")
  },
  {
    name: "networks",
    path: "/netvaerk",
    component: () => import("/opt/buildhome/repo/pages/networks.vue")
  },
  {
    name: "network",
    path: "/netvaerk/:id",
    component: () => import("/opt/buildhome/repo/pages/network.vue")
  },
  {
    name: "conferences",
    path: "/live-arrangementer",
    component: () => import("/opt/buildhome/repo/pages/conferences.vue")
  },
  {
    name: "conference",
    path: "/live-arrangementer/:id",
    component: () => import("/opt/buildhome/repo/pages/conference.vue")
  },
  {
    name: "facilities",
    path: "/partnerskaber-og-events",
    component: () => import("/opt/buildhome/repo/pages/facilities.vue")
  },
  {
    name: "yearbook",
    path: "/altinget20aar",
    component: () => import("/opt/buildhome/repo/pages/yearbook.vue")
  },
  {
    name: "comments-index",
    path: "/kommentarer",
    component: () => import("/opt/buildhome/repo/pages/comments.vue")
  },
  {
    name: "political-speeches-index",
    path: "/politiske-taler",
    component: () => import("/opt/buildhome/repo/pages/politicalSpeeches.vue")
  },
  {
    name: "products",
    path: "/produkter",
    component: () => import("/opt/buildhome/repo/pages/products.vue")
  },
  {
    name: "product_type",
    path: "/produkter/:typeId",
    meta: {"layout":"stripped"},
    component: () => import("/opt/buildhome/repo/pages/products.vue")
  },
  {
    name: "yearbook",
    path: "/yearbook",
    component: () => import("/opt/buildhome/repo/pages/yearbook.vue")
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/misc/:pathMatch(.*)",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/images/:pathMatch(.*)",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/Scripts/:pathMatch(.*)",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/scripts/:pathMatch(.*)",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/css/:pathMatch(.*)",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/pdffiles/:pathMatch(.*)",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/arena",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/praktik",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/praktik/",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/hotshot",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/hotshot/:pathMatch(.*)",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/verdensmaal",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/verdensmaal/:pathMatch(.*)",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/lyd",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/netv%C3%A6rk",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/navnenyt.aspx",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/jobannoncer",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/karriere",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/:paper/karriere",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/karriere/:pathMatch(.*)",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/:paper/job",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/fm",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/verdensmaalsugen",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/verdensmål",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/verdensm%C3%A5l",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/energi",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/sponsoreret/-kompetenceudvikling-sikrer-korrekt-sagsbehandling",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/kandidater/",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/kandidater",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/valg",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/valg/:pathMatch(.*)",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/etikogtro",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/artikel/priser-og-abonnementsbetingelser",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/podcast/1",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/podcast/2",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/podcast/3",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/podcast/4",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/podcast/5",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/podcast/6",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/podcast/7",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/podcast/8",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/podcast/9",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/podcast/10",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/podcast/11",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/artikel/set-og-hoert-paa-folkemoedet-demokratifesten-er-tilbage-for-fuld-skrald",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/emne/7427",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/artikel/2008-3-23-kontakt-til-altingetdk",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/artikel/staben",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/artikel/annoncer",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/civilsamfundetsvidenscenter",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/civilsamfundetsvidenscenter/:pathMatch(.*)",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/uddannelser",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/uddannelser/:pathMatch(.*)",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/navne",
    component: component_45stubGZr1UkTkg3
  }
]